body, html, #root {
  height: 100%;
}

body {
  overflow-y: scroll;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow-x: hidden;
  }
}

.w-300 {
  width: 300px;
}

.gong-video {
  width: 100%;
  max-width: 721px;
  max-height: 395px;
}

.card-body {
  overflow: hidden;
  table {
    height: 1px;
    min-width: 700px;
    td {
      height: 100%;
      @extend .align-items-center;
    }
  }
}